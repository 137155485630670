import { Experiment, Variant } from "@marvelapp/react-ab-test"
import { graphql, useStaticQuery } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import { BounceLoader } from "react-spinners"
import { About } from "../components/about"
import { ChristmasPromoModal } from "../components/christmas-promo-modal"
import { DeliverySection } from "../components/delivery"
import { DietologFormModal } from "../components/dietolog-consult-modal"
import { DiscountSection } from "../components/discount"
import { FAQ } from "../components/faq"
import { FAQ2 } from "../components/faq2"
import { FoodCarouselSection } from "../components/food"
import { FoodCarouselSection2 } from "../components/food2"
import { FoodCarouselSection3 } from "../components/food3"
import { Footer } from "../components/footer"
import { Footer2 } from "../components/footer2"
import { Header } from "../components/header"
import { Header2 } from "../components/header2"
import { Hero } from "../components/hero2"
import { Hero3 } from "../components/hero3"
import { HowItWork } from "../components/howitwork"
import { HowItWork2 } from "../components/howitwork-v2"
import { Loader } from "../components/loader"
import { MenuOrderInfo } from "../components/menuOrderInfo"
// import { NutritionistSection } from "../components/nutritionist"
import { Order } from "../components/order-form"
import { Order2 } from "../components/order-form2"
import { PreFooter } from "../components/prefooter"
import { Program } from "../components/program"
import { Program2 } from "../components/program-v2/"
import { Reviews } from "../components/reviews"
import { Reviews2 } from "../components/reviews2"
import { SaleFormModal } from "../components/sale-form-modal"
import { SaleFormModal2 } from "../components/sale-form-modal2"
import SEO from "../components/seo"
import { AffiliateContext } from "../contexts/AffiliateContext"
import { getUTM } from "../helpers"
import { Countdown } from "../components/countdown-block";
import { CookieAgreementModal } from "../components/cookie-agreement-modal"
import { Benefity } from "../components/benefity"
import { InfoBlock } from "../components/info-block"

// import { HomepageTabsProvider, homepageTabsReducer, initialState } from '../contexts/HomepageTabsContext';

// const Loader = () => (
//   <div
//     style={{
//       height: "100vh",
//       display: "flex",
//       flexDirection: "column",
//       alignItems: "center",
//       justifyContent: "center",
//     }}
//   >
//     <BounceLoader size={100} color="#3DC383" />
//   </div>
// )

const NewHomepage = ({ site }) => {
  // const [state, dispatch] = useReducer(homepageTabsReducer, initialState)
  const isEn = document.location.pathname.includes("/en")
  const affiliateContext = useContext(AffiliateContext)
  const { isAffiliate } = affiliateContext.affiliate

  /* AB-test to display specific design version of food section */
  // const [foodVersion, setFoodVersion] = useState("")
  // const versions = ["current-version", "new-version"]

  // useEffect(() => {
  //   const curVersion = localStorage.getItem("food-version")
  //   const randomIndex = getRandomInteger(1, 10) > 5 ? 1 : 0
  //   const randomVersion = versions[randomIndex]
  //   if (!curVersion || curVersion === undefined) {
  //     localStorage.setItem("food-version", randomVersion)
  //     setFoodVersion(randomVersion)
  //   } else {
  //     setFoodVersion(curVersion)
  //   }
  // }, [])

  // useEffect(() => {
  //   window.dataLayer = window.dataLayer || [];
  //   window.dataLayer &&
  //     window.dataLayer.push({
  //       event: "ga.pageview",
  //       pageURL: '/',
  //       testovani: foodVersion,
  //     })
  // }, [foodVersion]);

  // function getRandomInteger(min, max) {
  //   return Math.floor(Math.random() * (max - min + 1)) + min
  // }

  return (
    // <HomepageTabsProvider
    //   value={{ activeTab: state.activeTab, dispatchAction: dispatch }}
    // >
    <>
      <SEO 
        title="NutritionPro-Box | Gesunde Mahlzeiten von Küchenchefs zubereitet und für Sie geliefert"
        description="Testen Sie unsere NutritionPro-Boxen. Immer frisch, nie gefroren.  Nur hochwertige Zutaten von lokalen Lieferanten" 
      />
      <CookieAgreementModal/>
      {/* componetns here */}
      {/* <ChristmasPromoModal formName="christmas-promo-form" /> */}
      {/* {!isAffiliate && <SaleFormModal formName="sale-form" />} */}
      <SaleFormModal2 formName="Akce 2 dny" />
      {/* {!isAffiliate && <DietologFormModal formName="konzultace-dietolog" />} */}
      <Hero3 />
      <HowItWork2 />
      <InfoBlock />
      <Benefity />
      <MenuOrderInfo id="menu" />
      {/* <Countdown /> */}
      <div id="calculator2" />
      <Order2 id="calculator" />
      <Program2 id="programs" />
      {/* {foodVersion === "current-version" ? (<FoodCarouselSection2 />) : (<FoodCarouselSection3 />)} */}
      {/* <FoodCarouselSection2 /> */}
      <FoodCarouselSection3 />
      {/* <NutritionistSection /> */}
      <DeliverySection />
      <Reviews2 />
      <DiscountSection />
      <FAQ2 />
    </>
    // </HomepageTabsProvider>
  )
}

const OldHomepage = ({ site }) => (
  <>
    <SEO 
      title="NutritionPro-Box | Gesunde Mahlzeiten von Küchenchefs zubereitet und für Sie geliefert"
      description="Testen Sie unsere NutritionPro-Boxen. Immer frisch, nie gefroren.  Nur hochwertige Zutaten von lokalen Lieferanten" 
    />
    {site && <Header menuLinks={site.siteMetadata.menuLinks} />}
    <Hero />
    <Program id="programs" />
    <Order id="calculator" />
    <FoodCarouselSection />
    <About />
    <Reviews id="reviews" />
    <HowItWork />
    <FAQ id="faq" />
    <PreFooter />
    <Footer />
  </>
)

const IndexPage = ({ isAffiliate }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
              section
            }
          }
        }
      }
    `
  )

  /* AB-test to display specific design version */
  // const [pageVersion, setPageVersion] = useState("")
  // const versions = ["current-version", "new-version"]

  // useEffect(() => {
  //   const curVersion = localStorage.getItem("homepage-version")
  //   const randomIndex = getRandomInteger(1, 10) > 5 ? 1 : 0
  //   const randomVersion = versions[randomIndex]
  //   if (!curVersion || curVersion === undefined) {
  //     localStorage.setItem("homepage-version", randomVersion)
  //     setPageVersion(randomVersion)
  //   } else {
  //     setPageVersion(curVersion)
  //   }
  // }, [])

  // useEffect(() => {
  //   console.log("push datalayer pageVersion", pageVersion)
  //   window.dataLayer &&
  //     window.dataLayer.push({
  //       event: "ga.pageview",
  //       testovani: pageVersion,
  //     })
  // }, [pageVersion])

  // function getRandomInteger(min, max) {
  //   return Math.floor(Math.random() * (max - min + 1)) + min
  // }

  // for hiding sticky hero btns on mobile when scroll to main form

  const [isVisible, setIsVisible] = useState(true)

  const getOffsetStart = element => {
    const rect = element?.getBoundingClientRect()
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    return rect ? scrollTop + rect.top : scrollTop
  }

  const getOffsetEnd = element => {
    const rect = element?.getBoundingClientRect()
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    return rect ? scrollTop + rect.top + rect.height : scrollTop
  }

  const listenToScroll = () => {
    const hideElem = document.querySelector("#sticky-buttons")
    const elemWhenHide = document.querySelector("#calculator")
    const offsetToHideFromStart = getOffsetStart(elemWhenHide)
    const offsetToHideFromEnd = getOffsetEnd(elemWhenHide)
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    if (winScroll > offsetToHideFromStart && winScroll < offsetToHideFromEnd) {
      console.log("winScroll > offsetToHideFromStart")
      isVisible && setIsVisible(false) // to limit setting state only the first time
      hideElem.style.display = "none"
    } else {
      console.log("winScroll < offsetToHideFromStart")
      setIsVisible(true)
      hideElem.style.display = "flex"
    }
  }

  useEffect(() => {
    const isMobile = window.innerWidth < 812
    isMobile && window.addEventListener("scroll", listenToScroll)
    return () =>
      isMobile && window.removeEventListener("scroll", listenToScroll)
  }, [])


  useEffect(() => {
    window.localStorage.removeItem("deliveryPrice") // necessary for order form
  }, [])

  return (
    <>
      {/* {pageVersion === "current-version" ? (
        <OldHomepage site={site} />
      ) : pageVersion === "new-version" ? (
        <NewHomepage site={site} />
      ) : (
        <Loader />
      )} */}
      {site ? (
        <NewHomepage site={site} isAffiliate={isAffiliate} />
      ) : (
        <Loader />
      )}
    </>
  )
}

export default IndexPage
