import React, { useCallback, useEffect, useState } from 'react';
import cx from "classnames"
import Countdown from "react-countdown"

import styles from './counter2.module.css';

const EXPIRE_PERIOD = new Date("2022-07-15").getTime(); //

const Completionist = () => <span>Your discount already expired!</span>

const formatDateUnit = (unitNumber) => unitNumber.toString().length < 2 ? `0${unitNumber}` : unitNumber;

// Renderer callback with condition
const renderer = ({ days, hours, minutes, completed }) => {
    if (completed) {
        // Render a completed state
        return <Completionist />
    } else {
        // Render a countdown
        return (
            <span className={styles.counter}>
                <span className={styles.counterItem}>
                    <span className={styles.counterItemValue}>{formatDateUnit(days)}</span>
                    <span className={styles.counterItemUnitText}>days</span>
                </span>
                <span className={styles.counterItem}>
                    <span className={styles.counterItemValue}>{formatDateUnit(hours)}</span>
                    <span className={styles.counterItemUnitText}>hours</span>
                </span>
                <span className={styles.counterItem}>
                    <span className={styles.counterItemValue}>{formatDateUnit(minutes)}</span>
                    <span className={styles.counterItemUnitText}>minutes</span>
                </span>
            </span>
        );
    }
}

const CountdownBlock = () => {
    const [countdownTimestamp, setCountdownTimestamp] = useState(() => {
        return new Date().getTime() + EXPIRE_PERIOD - new Date().getTime();
    });

    const handleSetCountdown = useCallback(() => {
        const countdown = localStorage.getItem("countdown")
        if (countdown) {
            const countdownValue = JSON.parse(countdown)
            const diff = new Date().getTime() - countdownValue.time
            if (diff >= countdownValue.expire) {
                console.log("Expired!")
            } else {
                setCountdownTimestamp(countdownValue.expire - diff)
            }
        } else {
            const obj = {
                time: new Date().getTime(),
                expire: EXPIRE_PERIOD,
            }
            const objStr = JSON.stringify(obj)
            localStorage.setItem("countdown", objStr)
        }
    }, [])

    useEffect(() => {
        handleSetCountdown()
        console.log("countdownTimestamp", countdownTimestamp);
    }, [handleSetCountdown, countdownTimestamp]);

    return (
        <div className={styles.counter}>
            {countdownTimestamp !== 0 && (
                <Countdown date={countdownTimestamp} renderer={renderer} />
            )}
        </div>
    );
};

export const Counter2 = () => <CountdownBlock />;
